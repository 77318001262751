import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string; // Possible values: link/dropDown/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false,
  };
  selectedItem: IMenuItem;

  constructor() {}

  defaultMenu: IMenuItem[] = [
     {
      name: "แดชบอร์ด",
      description: "VENDER LIST",
      type: "link",
      icon: "i-Bar-Chart",
      state: "/dashboard/all",
    },
  
 {
      name: "รายการงานทั้งหมด",
      description: "",
      type: "link",
      icon: "i-Windows-2",
      state: "/followlist/all",
    },
    {
      name: "รายการงานในโครงการ",
      description: "VENDER LIST",
      type: "link",
      icon: "i-File-Horizontal-Text",
      state: "/followlist/list",
    },
    {
      name: "รายการงานที่ฉันรับผิดชอบ",
      description: "VENDER LIST",
      type: "link",
      icon: "i-Administrator",
      state: "/followlist/myjob",
    },
     {
      name: "รายการงานที่ฉันเป็นผู้แจ้ง",
      description: "VENDER LIST",
      type: "link",
      icon: "i-Double-Tap",
      state: "/followlist/mycreated",
    },
    {
      name: "รายงาน",
      type: "link",
      tooltip: "Documentation",
      icon: "i-Align-Left",
      state: "/followlist/report",
    }, 
  ];

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // You can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (userType) {
  //     case 'admin':
  //       this.menuItems.next(this.adminMenu);
  //       break;
  //     case 'user':
  //       this.menuItems.next(this.userMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }
}
